/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines: 

https://polaris.corp.amazon.com/getting_started/development/integration/
************************************************************************/
//import React from 'react';
import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
} from "@amzn/awsui-components-react";
import { Route } from 'react-router-dom';

import WorkshopHome from './WorkshopHome.jsx';
import WorkshopSecond from "./WorkshopSecond.jsx";

import '@amzn/awsui-global-styles/polaris.css';

// DK - Integrating Authentication
import Amplify from "aws-amplify";
import { Auth, Hub } from "aws-amplify";

import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import config from "../aws-exports";

Amplify.configure(config);

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      // eslint-disable-next-line default-case
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });

    getUser().then((userData) => {
      console.log(userData)
      setUser(userData);
    });
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  return (
    <div >
      {user ? (
        <div>
      <div>
        <Route exact path="/" component={WorkshopHome} />
        <Route path="/second" component={WorkshopSecond} />
      </div>
        </div>
      ) : (
        <Grid gridDefinition={[{ colspan: 6, offset: 3 }]}>
          <Box className="login-form" textAlign="center" color="inherit">
            <h1 color="#ff9900;">Welcome to your new Internal App!</h1>
            <Button
              variant="primary"
              onClick={() =>
                Auth.federatedSignIn({ provider: "AmazonFederate" })
              }
            >
              Login With Amazon Midway
            </Button>
          </Box>
        </Grid>
      )};
    </div>
  );
};

export default App;
