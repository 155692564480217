/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const host = (window.location.hostname === "localhost")?"http://localhost:3000": "https://"+window.location.hostname
const EnvAttributes = () =>{
    switch (window.location.hostname){
        case "ialek.people.aws.dev":
            return {
                CognitoDomain: "workshop-ialek.auth.us-east-1.amazoncognito.com",
                CognitoIPool: "us-east-1:d5365e8f-b230-4985-8744-4898e5c175f1",
                CognitoUPool: "us-east-1_YNtHsrDCF",
                CognitoWebClientID: "41ruqqeu80mcqne9g6qrku1ljp"
            }
        }
    }

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": EnvAttributes().CognitoIPool,
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": EnvAttributes().CognitoUPool,
    "aws_user_pools_web_client_id": EnvAttributes().CognitoWebClientID,
    "oauth": {
        domain: EnvAttributes().CognitoDomain,
        scope: ["profile", "openid", "aws.cognito.signin.user.admin"],
        redirectSignIn: host, redirectSignOut: host,
        responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS"
};

export default awsmobile;
