/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React from 'react';
import { Link } from 'react-router-dom';
import {
  AppLayout,
  SideNavigation,
  Container,
  Header,
  HelpPanel,
  Grid,
  Box,
  TextContent,
  SpaceBetween
} from '../aws-ui-components';

import '../styles/intro.scss';
import '../styles/servicehomepage.scss';

// This is not meant to be a template, rather it is the
// introduction page that you see upon loading the webserver.
export default function WorkshopSecond() {
  return (
    <AppLayout
      navigation={<CustomNavigation />}
      content={<Content />}
      tools={<Tools />}
      disableContentPaddings={true}
    />
  );
}

const Content = () => (
  <div>
    <TextContent>
      <div>
        <Grid className="custom-home__header" disableGutters={true}>
          <Box margin="xxl" padding={{ vertical: 'xl', horizontal: 'l' }}>
            <div className="custom-home__header-title">
              <Box fontSize="display-l" fontWeight="bold" color="inherit">
                PSA Offsite Workshop
              </Box>
              <Box fontSize="display-l" padding={{ bottom: 's' }} fontWeight="light" color="inherit">
                Building Internal Apps with CDK
              </Box>
              <Box fontWeight="light">
                <span className="custom-home__header-sub-title">
                </span>
              </Box>
            </div>
          </Box>
        </Grid>
      </div>

      <Box margin="xxl" padding="l">
        <SpaceBetween size="l">
          <div>
            <h1>2nd Page</h1>
            <Container>
              <div>
                    Just showing a 2nd page to show navigation.
              </div>
            </Container>
          </div>
        </SpaceBetween>
      </Box>

    </TextContent>
  </div>
);

const CustomNavigation = () => (
  <SideNavigation
    header={{ text: 'Navigation panel', href: '#/' }}
    items={[
      {
        type: 'link', text: 'Home', href: '#/',
        type: 'link', text: 'Second Page', href: '#/second'
      }
    ]}
    activeHref="#/"
  />
);

const Tools = () => (
  <HelpPanel header={<h2>Help panel</h2>}>
    <p>Help content goes here</p>
  </HelpPanel>
);
